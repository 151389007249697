.centered-div {
    margin: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 200px;
  }

  .middle-div {
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .service-text-div{
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    display: flex;
  }

  .service-title-div{
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  @media (max-width: 1200px){
    .service-text-div{
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
      min-width: 300px;
    }

    .service-title-div{
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
      display: flex;
      justify-content: center;
      min-width: 300px;
    }

    .service-list-div{
      width: 1000px;
    }
  }

  @media (max-width: 350px){
    .service-title-div{
      min-width: none;
    }

    .service-text-div{
      min-width: none;
    }
  }