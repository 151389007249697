body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* padding-top: 50px; */
  padding-top: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: white;
  font-family: Arial, sans-serif,'Courier New', Courier, monospace;
  color: black;
}


/* h1, h2, h3, h4, h5, h6 {
  text-shadow: 2px 2px 4px #000000;
  background-color: "#e000f3"
} */

.shodow {
  text-shadow: 2px 2px 4px #000000;
}

hr {
  border-top: 1px dashed #000000;
}

.footer {
  background-color: RGBA(33,37,41)!important;
  color: white;
  padding: 20px;
  text-align: center;
}
