.home-carousel .carousel-item {
    height: 500px; /* Adjust this value as needed */
    object-fit: contain;
    overflow: hidden;
    background-color: #d09ef2;
    display: flex;
  }
  
  .home-carousel .carousel-item img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .carousel-half {
    height: 100%;
    width: 50%;
    object-fit: cover;
    overflow: hidden;
    background-color: red;
    display: flex;
  }

  .center-div {
    margin: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .home-background {
    background-color: #fce0f4;

  }

  .home-background-two{
    background-color: #ffffff;
    margin-top: 100px;
  } 

  .in-page-product {
    width: 100%;
    height: auto;
    object-position: left;
    display: block;
    position: relative;
    bottom: -100px; 
  }

  .in-page-product-text{
    margin-top: 200px;
    margin-bottom: 50px;
  }

  .three-word-line{
    max-width: 200px;
    text-align: center;
    margin: auto;
    height: 50px;
  }

  .img-row{
    height: 100px;
  }

  .gallery-img{
    height: 350px;
    width: 100%;
    object-fit: contain;
  }

  .increased-width-div{
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    display: flex;
    margin-top: 140px;
  }

  .pre-img-product-3{
    margin-top: 150px;
  }

  @media (max-width: 1000px){
    .background-cast-b-75{
      flex-direction: column;
    }

    .in-page-product{
      margin-right: 0;
      margin-bottom: 50px;
      bottom: 0;
    }

    .in-page-product-text{
      margin-top: 0;
    }

    .pre-img-product-3{
      margin-top: 80px;
    }
  }
  
