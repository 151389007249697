/* .carousel-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
 } */

.parent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items:  stretch;
 }
    
.child {
    flex-grow: 1;
    text-align: center;
 }

 .child img {
    width: 100%;
    height: 500px;
    object-fit: contain;
    object-position: left;
 }
