:root{
    --primary: #d500f9;
    --secondary: white;
}

.routeBtnContainer{
    max-height: 50px;
    width: fit-content;
}

.btn{
    font-family: sans-serif;
    border-radius: 5px;
    font-weight: bold;
    position: relative;
    max-width: 100%;
}


.btn--animate{
    cursor: pointer;
    transition: transform 0.3s ease;
}
.btn--animate:hover{
    transform: translateY(-5px);
}

.btn--solid {
    background-color: var(--primary);
    color: white;
    border-width: 2px;
    border-color: var(--primary);
}

.btn--solid:hover{
    color: var(--primary);
    background-color: white;
    border-width: 2px;
    border-color: var(--primary);
}

.btn--outline{
    color: var(--primary);
    background-color: white;
    border-width: 2px;
    border-color: var(--primary);
}

.btn--outline:hover{
    background-color: var(--primary);
    color: white;
    border-width: 2px;
    border-color: var(--primary);
}