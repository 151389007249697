.about-container {
    width: 80%;
    margin: auto;
    padding: 20px 0;
  }
  
  .title {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  h2 {
    font-size: 2em;
    color: #666;
    margin-top: 40px;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 1.2em;
    line-height: 1.5;
  }